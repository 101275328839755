<template>
  <div class="activity container">
    <Carousel height="720px" theme="banner" />
    <div class="page-intro text-center">
      <div class="page-title">
        <div class="tag">Activity</div>
        <h1>Snowbaord Lesson</h1>
      </div>
      <div class="page-content">
        <p>It really does snow almost every night here at Chill Factor.</p>
        <p>
          If you’ve ever wondered how we make our snow, we use the same ingredients as Mother Nature:<br />
          air and water - that’s it!
        </p>
        <p>
          At night, snow falls for four hours from the 14 snow cannons above our slopes,<br />
          and the mounds and dips created by our guests during the day are removed by our expert<br />
          snow groomers using the famous Chill Factor Pisten Bully.
        </p>
        <div class="tips">***Activity provide to : Guests stay in <span>Qfox Ruby Hotel</span></div>
      </div>
    </div>
    <div class="p20">
      <div class="activity-list">
        <div class="item">
          <div class="item-pics">
            <Carousel height="396px" />
          </div>
          <div class="item-content">
            <div class="item-head">
              <div class="title">Beginner</div>
            </div>
            <ul class="intro">
              <li class="serve">Serve: group of 4 guests</li>
              <li class="duration">Duration: 30 minutes</li>
              <li class="cost">Cost: JPD 10000 per person</li>
              <li class="availability">Availability: December - March</li>
            </ul>
            <div class="item-footer">
              <el-button type="info" round>Show Detail</el-button>
              <el-button type="primary" round>RESERVE</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Activity',
  components: {
    Carousel,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
